{
  "app": {
    "title": "FiPS Transit",
    "lang-en": "English",
    "lang-ja": "Japanese"
  },
  "nav": {
    "recents": "Usage History",
    "cards": "Cards",
    "accounts": "My Account"
  },
  "sign-in": {
    "title": "Log in to your acount",
    "submit": "Sign In",
    "goto-sign-up": "Don't have an acount? Sign up",
    "goto-reset-password": "Forgot your password? Reset here.",
    "social-idp-divider": "Or",
    "continue-with-google": "Sign in with Google",
    "continue-with-facebook": "Sign in with Facebook",
    "continue-with-twitter": "Sign in with Twitter",
    "invalid-email": "Email Address is required",
    "invalid-password": "Password is required"
  },
  "sign-up": {
    "title": "Create your acount",
    "submit": "Sign Up",
    "goto-sign-in": "Already have an account? Sign in",
    "input-first-name": "First Name",
    "input-last-name": "Last Name",
    "input-email": "Email Address",
    "input-password": "Password",
    "input-allow-extra-emails": "I want to receive inspiration, marketing promotions and updates via email.",
    "autofill-first-name": "given-name",
    "autofill-last-name": "family-name"
  },
  "signup-sending": {
    "message-send": "Sending email...",
    "message-sent": "Email sent",
    "message-failed": "Unable to send email",
    "button-resend": "Resend",
    "button-back": "Back"
  },
  "signup-verify": {
    "message-signing": "Verifying email link",
    "message-signed": "Email link verification completed",
    "message-failed": "Email link verification failed",
    "button-continue": "Continue"
  },
  "signup-complete": {
    "title": "Thank you for your registration",
    "description": "Complete your account registration by filling out the form below.",
    "submit": "Complete",
    "input-first-name": "First Name",
    "input-last-name": "Last Name",
    "input-email": "Email Address",
    "input-password": "Login Password",
    "input-password-confirm": "Confirm Password",
    "input-allow-extra-emails": "I want to receive inspiration, marketing promotions and updates via email.",
    "autofill-first-name": "family-name",
    "autofill-last-name": "given-name",
    "input-user-name": "Name",
    "autofill-user-name": "John Smith",
    "input-kana": "kana (optional)",
    "autofill-kana": ""
  },
  "reset-password": {
    "title": "Forgot password?",
    "input-email": "Email Address",
    "submit": "Reset Password",
    "back": "Back",
    "success": "We have sent you a link to reset your password. Please check your email inbox.",
    "description": "Enter the email address associated with your account and we will send you a link to reset your password."
  },
  "agreement": {
    "title": "Registration",
    "submit": "Continue",
    "description": "By clicking \"Continue\" below, you agree to our \"Terms of Service\" and \"Privacy Policy\"",
    "goto-terms-of-service": "Terms of Service",
    "goto-terms-of-service-url": "/terms-of-service-en.html",
    "goto-privacy-policy": "Privacy Policy",
    "goto-privacy-policy-url": "https://www.mcinc.jp/privacy/"
  },
  "recents": {
    "title": "Usage history",
    "month-format": "MMM YYYY",
    "month-this": "This month",
    "month-last": "Last month",
    "month-last2": "The month before last",
    "monthly-total-text": "Total usage amount",
    "date-format": "MMM D",
    "no-record": "No history",
    "pull-down-refresh": "Pull down to refresh",
    "release-refresh": "Release to refresh",
    "select-date": "Select date"
  },
  "recents-dialog": {
    "title": "Usage details",
    "transit-id": "Transit ID",
    "agency-name": "Agency Name",
    "entrance": "Entered At",
    "exit": "Exited At",
    "attributes": "Passengers",
    "attributes-adult": "Adult",
    "attributes-adult-discount": "Adult Discounted",
    "attributes-child": "Child",
    "attributes-child-discount": "Child Discounted",
    "fare": "Fare amount",
    "remarks": "Remarks",
    "inquiry-tel": "Inquiry Phone No.",
    "button-inquiry": "Make a phone call",
    "credit-card": "Your card",
    "button-open": "Show details",
    "button-close": "OK"
  },
  "receipt": {
    "title": "receipt",
    "address": "宛名",
    "honorific": "様",
    "fare": "金額",
    "taxin": "(税込)",
    "method": "支払方法",
    "credit": "クレジットカード",
    "bought": "商品情報",
    "word1": "但し",
    "word2": "乗車料金として",
    "word3": "※本書は電子的に保持している領収データを画面に表示したものです。",
    "area": "区間",
    "people": "人数",
    "usedate": "ご利用日",
    "transit-id": "Transit ID",
    "date-format": "YYYY 年 M月 D日",
    "placeholder": "（placeholder）",
    "fare-attributes": {
      "adult": "大人",
      "child": "小人",
      "adult-discount": "大人割引",
      "child-discount": "小人割引"
    },
    "download": "download"
  },
  "cards": {
    "title": "Card Registration Information",
    "button-delete": "Delete",
    "success-delete": "Card information has been deleted.",
    "success-register": "Card registration has been completed.",
    "no-record": "No card information is registered.",
    "regist-no-url": "Failed to show the registration page.",
    "regist-error-url": "Failed to show the registration page.",
    "pull-down-refresh": "Pull down to refresh",
    "release-refresh": "Release to refresh"
  },
  "register-card-dialog": {
    "title": "Card Registration",
    "description": "Enter your card information and press 'Register'",
    "input-account-number": "Card Account Number",
    "input-expiry": "Expiration",
    "input-holder-name": "Holder Name",
    "button-cancel": "Cancel",
    "button-register": "Register",
    "success": "Card registration has been completed."
  },
  "regist-card-result": {
    "title-loading": "Card Registration in progress...",
    "title-stopped": "The process was interrupted due to a problem.",
    "title-success": "Card registration has been completed.",
    "title-error": "The process was interrupted due to a problem.",
    "button-back": "Back"
  },
  "reauth-dialog": {
    "input-password": "Password Confirmation",
    "invalid-password": "Enter your password",
    "button-cancel": "Cancel",
    "button-submit": "OK"
  },
  "account": {
    "header-login": "Login Settings",
    "header-account": "Account Information",
    "name": "Name",
    "name-is-empty": "Empty",
    "email": "Email Address",
    "email-is-empty": "Empty",
    "email-is-not-verified": "Your email address has not been verified. Please check your email and click on the link.",
    "input-allow-extra-emails": "Receive email updates and more.",
    "password-change": "Change Password",
    "logout": "Logout",
    "withdrawal": "Delete Account",
    "result-alert-message": "Update process was aborted due to an error."
  },
  "account-signout-confirm": {
    "title": "Logout",
    "description": "Do you want to logout?",
    "confirm": "Proceed",
    "close": "Cancel"
  },
  "account-delete-confirm": {
    "title": "Delete Account",
    "description": "Do you want to delete your account?",
    "confirm": "Delete",
    "close": "Cancel"
  },
  "edit-account": {
    "title": "Update Account",
    "description": "Update your registered account information.",
    "input-user-name": "Name",
    "autofill-user-name": "山田 太郎",
    "input-kana": "Kana *Optional",
    "autofill-kana": "ヤマダ タロウ",
    "submit": "Update",
    "back": "Back",
    "result-alert-message": "Update process was aborted due to an error."
  },
  "edit-account-confirm": {
    "title": "Confirmation",
    "description": "Do you want to update the information?",
    "confirm": "OK",
    "close": "Cancel"
  },
  "edit-account-result": {
    "title": "Update Completed",
    "description": "Update has been completed.",
    "close": "OK"
  },
  "change-email": {
    "title": "Change Email Address",
    "description": "Change the email address used for login.",
    "success": "Your email address has been changed. We have sent you a confirmation email",
    "input-email": "Email Address",
    "submit": "Change Email",
    "back": "Back"
  },
  "change-password": {
    "title": "Change Password",
    "success": "Password has been changed.",
    "input-password-current": "Current Password",
    "input-password-new": "New Password",
    "input-password-confirm": "Confirm Password",
    "password-current-is-invalid": "Enter passwords",
    "password-new-is-same": "Same password",
    "submit": "Change",
    "back": "Back"
  },
  "auth-action": {
    "success-recovery-email": "Your email address change has been canceled.",
    "success-verify-email": "Your email address has been verified.",
    "back-to-home": "Back to home"
  },
  "confirm-password-reset": {
    "title": "Reset Password",
    "success": "Password is successfully reset.",
    "input-email": "Account Email",
    "input-password": "New Password",
    "input-password-confirm": "Confirm Password",
    "submit": "Reset",
    "back-to-home": "Back to Home"
  },
  "validation": {
    "email-is-empty": "Email address is blank",
    "email-is-invalid": "Enter your email address",
    "password-is-too-short": "Password must be at least 8 characters long and contain at least one alphabetical character, one number, and one symbol",
    "password-is-invalid": "Password contains invalid characters",
    "password-is-insecure": "Password must contain at least one alphabetical character, one number, and one symbol",
    "password-is-not-same": "Passwords entered are not the same",
    "first-name-is-invalid": "Enter your first name",
    "last-name-is-invalid": "Enter your last name",
    "user-name-is-invalid": "Enter your name",
    "card-number-length-is-not-in-the-range": "Enter a 14 to 19 digit 'number' for the card number",
    "card-number-is-invalid": "Card number contains invalid characters",
    "card-expiry-length-is-not-in-the-range": "Card expiration date is invalid",
    "card-expiry-is-invalid": "Card expiration date contains invalid characters",
    "card-holder-name-length-is-not-in-the-range": "Cardholder is blank",
    "card-holder-name-is-invalid": "Cardholder contains invalid characters"
  },
  "error-message": {
    "title": "Error",
    "app": {
      "Network Error": "Network Error",
      "userfront-invalid-argument": "Invalid Argument Error",
      "userfront-record-not-found": "No Record Found",
      "userfront-duplicate-key": "Duplicate Key Error",
      "userfront-not-available": "Not Available Error",
      "userfront-execute-api": "API Execution Error",
      "userfront-internal": "Internal Server Error"
    },
    "auth": {
      "federated-signin-error": "An error has occurred.",
      "password-not-support": "Email address or password is incorrect.",
      "verify-action-failure": "An error has occurred.",
      "sign-in-error": "Login failed.",
      "sign-out-error": "Logout failed.",
      "fetch-user-error": "Failed to obtain account information.",
      "delete-user-error": "An error occurred while deleting the account.",
      "auth-invalid-email": "Email address entered incorrectly.",
      "auth-app-deleted": "The instance has been deleted and cannot be used.",
      "auth-app-not-authorized": "API authentication error",
      "auth-argument-error": "Invalid Argument",
      "auth-invalid-api-key": "Invalid API Key",
      "auth-invalid-user-token": "Invalid User Token",
      "auth-invalid-tenant-id": "Invalid Tenant ID",
      "auth-network-request-failed": "Network Error",
      "auth-operation-not-allowed": "This operation is not permitted",
      "auth-requires-recent-login": "Re-signin",
      "auth-too-many-requests": "Too many requests",
      "auth-unauthorized-domain": "Domain Authorization Error",
      "auth-user-disabled": "User is disabled",
      "auth-user-token-expired": "User token is expired",
      "auth-web-storage-unsupported": "Your browser does not meet the requirements.",
      "auth-user-not-found": "There are no registered users matching the information you entered.",
      "auth-wrong-password": "Email address or password is incorrect."
    },
    "signup": {
      "signup-send-email-link-error": "Invalid link.",
      "signup-email-not-found": "Email address not found.",
      "signup-email-link-invalid": "Invalid link.",
      "signup-signing-email-link-error": "Invalid link.",
      "auth-expired-action-code": "This link is expired.",
      "auth-invalid-email": "Email address entered incorrectly.",
      "auth-user-disabled": "User is disabled",
      "auth-argument-error": "Invalid Argument",
      "auth-missing-android-pkg-name": "Android application package name is not set",
      "auth-missing-continue-uri": "Callback URL is not set",
      "auth-missing-ios-bundle-id": "Bundle ID for iOS app is not set",
      "auth-invalid-continue-uri": "Callback URL is invalid",
      "auth-unauthorized-continue-uri": "Unauthenticated callback URL"
    },
    "reauth": {
      "auth-invalid-password": "Password verification failed."
    },
    "etc-error": "Unknown Error"
  },
  "not-found": "The page you are looking for could not be found."
}