import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// mui
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

// src
import { useReauth } from '~src/hooks/reauth'
import { useRootSelector } from '~src/stores'
import { LoadingProgressSimple } from './loading-progress'

export type ReauthDialogProps = {
  open: boolean
  onClose(): void
  onAccept(): void
}

export function ReauthDialog(props: ReauthDialogProps) {
  const user = useRootSelector(state => state.auth.user)
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordErr, setPasswordErr] = useState<string | undefined>()

  const {
    isValidating,
    errorInfo: err,
    mutate,
    reset
  } = useReauth()

  const handleClose = () => {
    props.onClose()
  }

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleClose()
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // バリデーションチェック
    const vrs = [
      validatePasswordText(password)
    ]
    if (vrs.some(it => it)) {
      return
    }

    // パスワード(再)認証
    mutate({ password })
      .then(success => {
        if (success) {
          props.onAccept()
          handleClose()
        }
      })
      .catch(e => {
        console.log(e);
      })
  }

  const validatePasswordText = (text: string) => {
    const invalid = text.trim().length <= 0
    setPassword(text)
    setPasswordErr(invalid ? 'reauth-dialog.invalid-password' : undefined)
    return invalid
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    validatePasswordText(event.target.value)
  }

  const handleShowPasswordClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setShowPassword(!showPassword)
  }

  const clear = () => {
    // 表示をクリア
    setPassword('')
    setPasswordErr(undefined)
  }

  useEffect(() => {
    if (!props.open) {
      // ダイアログが閉じる
      setTimeout(() => {
        reset()
        clear()
      }, 0)
    }
  }, [props.open])

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        {(!isValidating && err) &&
          <Alert severity="error" sx={{ mx: 2, mt: 2 }}>
            {/* {err.err?.message ?? err.code} */}
            <Box component="small">{t('error-message.reauth.auth-invalid-password')}</Box>
          </Alert>
        }
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              id="reauth-email"
              name="email"
              value={user?.email}
              sx={{ display: 'none' }}
            />
            <TextField
              autoFocus
              required
              fullWidth
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton tabIndex={-1} onClick={handleShowPasswordClick}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              margin="normal"
              id="reauth-password"
              name="password"
              label={t('reauth-dialog.input-password')}
              value={password}
              error={passwordErr != null}
              helperText={passwordErr ? t(passwordErr) : undefined}
              onChange={handleChangePassword}
              autoComplete={'current-password'}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>{t('reauth-dialog.button-cancel')}</Button>
            <Button type="submit">{t('reauth-dialog.button-submit')}</Button>
          </DialogActions>
        </Box>
      </Dialog>

      <LoadingProgressSimple open={isValidating} />
    </>
  )
}
