{
  "app": {
    "title": "FiPS トランジット",
    "lang-en": "English",
    "lang-ja": "日本語"
  },
  "nav": {
    "recents": "利用履歴",
    "cards": "カード登録",
    "accounts": "アカウント"
  },
  "sign-in": {
    "title": "マイページにログイン",
    "submit": "サインイン",
    "goto-sign-up": "アカウント作成される方はこちら",
    "goto-reset-password": "パスワードをお忘れですか？",
    "social-idp-divider": "または",
    "continue-with-google": "Google でサインイン",
    "continue-with-facebook": "Facebook でサインイン",
    "continue-with-twitter": "Twitter でサインイン",
    "invalid-email": "Eメールアドレスを入力してください",
    "invalid-password": "パスワードを入力してください"
  },
  "sign-up": {
    "title": "アカウントを作成",
    "submit": "サインアップ",
    "goto-sign-in": "既にアカウントをお持ちの方はこちら",
    "input-first-name": "姓",
    "input-last-name": "名前",
    "input-email": "Eメールアドレス",
    "input-password": "パスワード",
    "input-allow-extra-emails": "最新情報などのEメールを受信します.",
    "autofill-first-name": "family-name",
    "autofill-last-name": "given-name"
  },
  "signup-sending": {
    "message-send": "メールを送信...",
    "message-sent": "メールを送信しました",
    "message-failed": "メールを送信できません",
    "button-resend": "再送する",
    "button-back": "戻る"
  },
  "signup-verify": {
    "message-signing": "メールリンクを検証しています",
    "message-signed": "メールアドレスが確認できました",
    "message-failed": "メールリンクの検証に失敗しました",
    "button-continue": "続ける"
  },
  "signup-complete": {
    "title": "ご登録ありがとうございます",
    "description": "以下のフォームを入力して、アカウント登録を完了します.",
    "submit": "アカウント登録",
    "input-first-name": "姓",
    "input-last-name": "名前",
    "input-email": "Eメールアドレス",
    "input-password": "パスワード",
    "input-password-confirm": "パスワード（確認）",
    "input-allow-extra-emails": "最新情報などのEメールを受信します.",
    "autofill-first-name": "family-name",
    "autofill-last-name": "given-name",
    "input-user-name": "名前",
    "autofill-user-name": "山田太郎",
    "input-kana": "カナ (任意)",
    "autofill-kana": "ヤマダタロウ"
  },
  "reset-password": {
    "title": "パスワードをお忘れですか？",
    "input-email": "Eメールアドレス",
    "submit": "パスワードリセット",
    "back": "戻る",
    "success": "パスワードリセットのリンクを送信しました. メールをご確認ください.",
    "description": "アカウントに登録されたEメールアドレスを入力してください. パスワードリセットのリンクをお送りします."
  },
  "agreement": {
    "title": "会員登録",
    "submit": "同意して続ける",
    "description": "サービス利用規約と個人情報保護方針をお読みの上、ご了承いただける場合は「同意して続ける」にお進みください.",
    "goto-terms-of-service": "サービス利用規約",
    "goto-terms-of-service-url": "/terms-of-service-ja.html",
    "goto-privacy-policy": "個人情報保護方針",
    "goto-privacy-policy-url": "https://www.mcinc.jp/privacy/"
  },
  "recents": {
    "title": "利用履歴",
    "month-format": "YYYY年 M月",
    "month-this": "今月",
    "month-last": "先月",
    "month-last2": "先々月",
    "monthly-total-text": "ご利用金額合計",
    "date-format": "M月 D日",
    "no-record": "履歴がありません",
    "pull-down-refresh": "引いて更新",
    "release-refresh": "離して更新",
    "select-date": "日付を選択"
  },
  "recents-dialog": {
    "title": "乗車明細",
    "transit-id": "支払い番号",
    "agency-name": "交通事業者",
    "entrance": "入場/乗車",
    "exit": "出場/降車",
    "attributes": "乗車人数",
    "attributes-adult": "大人",
    "attributes-adult-discount": "大人割引",
    "attributes-child": "小人",
    "attributes-child-discount": "小人割引",
    "fare": "ご利用金額",
    "remarks": "備考",
    "inquiry-tel": "問合せ先",
    "button-inquiry": "電話する",
    "credit-card": "ご利用カード",
    "button-open": "明細表示",
    "button-close": "閉じる"
  },
  "receipt": {
    "title": "領収書",
    "address": "宛名",
    "honorific": "様",
    "fare": "金額",
    "taxin": "(税込)",
    "method": "支払方法",
    "credit": "クレジットカード",
    "bought": "商品情報",
    "word1": "但し",
    "word2": "乗車料金として",
    "word3": "※本書は電子的に保持している領収データを画面に表示したものです。",
    "area": "区間",
    "people": "人数",
    "usedate": "ご利用日",
    "transit-id": "支払い番号",
    "date-format": "YYYY 年 M月 D日",
    "placeholder": "（宛名を入力）",
    "fare-attributes": {
      "adult": "大人",
      "child": "小人",
      "adult-discount": "大人割引",
      "child-discount": "小人割引"
    },
    "download": "ダウンロード"
  },
  "cards": {
    "title": "カード登録情報",
    "button-delete": "削除",
    "success-delete": "カード情報を削除しました.",
    "success-register": "カード登録が完了しました.",
    "no-record": "カード情報の登録がありません",
    "regist-no-url": "登録画面の表示に失敗しました.",
    "regist-error-url": "登録画面の表示に失敗しました.",
    "pull-down-refresh": "引いて更新",
    "release-refresh": "離して更新"
  },
  "register-card-dialog": {
    "title": "カード登録",
    "description": "カード情報を入力して「登録」を押してください.",
    "input-account-number": "カード番号",
    "input-expiry": "カード有効期限",
    "input-holder-name": "カード名義人",
    "button-cancel": "キャンセル",
    "button-register": "登録",
    "success": "カード登録が完了しました."
  },
  "regist-card-result": {
    "title-loading": "カード登録中...",
    "title-stopped": "問題が発生したため、処理を中断しました.",
    "title-success": "カード登録が完了しました.",
    "title-error": "問題が発生したため、処理を中断しました.",
    "button-back": "戻る"
  },
  "reauth-dialog": {
    "input-password": "パスワード",
    "invalid-password": "パスワードを入力してください",
    "button-cancel": "キャンセル",
    "button-submit": "OK"
  },
  "account": {
    "header-login": "ログイン設定",
    "header-account": "アカウント情報",
    "name": "名前",
    "name-is-empty": "未設定",
    "email": "Eメールアドレス",
    "email-is-empty": "未設定",
    "email-is-not-verified": "メールアドレスの確認が取れていません. メールが届いているか確認し、リンクをクリックしてください.",
    "input-allow-extra-emails": "最新情報などのEメールを受信します.",
    "password-change": "パスワード変更",
    "logout": "ログアウト",
    "withdrawal": "アカウント削除",
    "result-alert-message": "エラーが発生したため、変更処理を中断しました."
  },
  "account-signout-confirm": {
    "title": "サインアウトの確認",
    "description": "サインアウトしますか?",
    "confirm": "実行",
    "close": "キャンセル"
  },
  "account-delete-confirm": {
    "title": "退会の確認",
    "description": "退会しますか?",
    "confirm": "実行",
    "close": "キャンセル"
  },
  "edit-account": {
    "title": "アカウント変更",
    "description": "登録されたアカウント情報を変更します.",
    "input-user-name": "名前",
    "autofill-user-name": "山田 太郎",
    "input-kana": "カナ (任意)",
    "autofill-kana": "ヤマダ タロウ",
    "submit": "変更",
    "back": "戻る",
    "result-alert-message": "エラーが発生したため、変更処理を中断しました."
  },
  "edit-account-confirm": {
    "title": "確認",
    "description": "入力された内容で変更します. よろしいですか?",
    "confirm": "OK",
    "close": "キャンセル"
  },
  "edit-account-result": {
    "title": "変更完了",
    "description": "変更が完了しました.",
    "close": "OK"
  },
  "change-email": {
    "title": "Eメールアドレス変更",
    "description": "ログインで使用するEメールアドレスを変更します.",
    "success": "ログインメールアドレスを変更しました. 確認メールを送信しましたので、ご確認ください.",
    "input-email": "Eメールアドレス",
    "submit": "変更",
    "back": "戻る"
  },
  "change-password": {
    "title": "パスワード変更",
    "success": "パスワードを変更しました.",
    "input-password-current": "現在のパスワード",
    "input-password-new": "新しいパスワード",
    "input-password-confirm": "新しいパスワード（確認）",
    "invalid-password-current": "パスワードを入力してください",
    "password-new-is-same": "同じパスワードです",
    "submit": "変更",
    "back": "戻る"
  },
  "auth-action": {
    "success-recovery-email": "メールアドレスの変更を取り消しました.",
    "success-verify-email": "メールアドレスが確確できました.",
    "back-to-home": "ホーム画面に戻る"
  },
  "confirm-password-reset": {
    "title": "パスワードリセット",
    "success": "パスワードをリセットしました.",
    "input-email": "アカウント Eメール",
    "input-password": "新しいパスワード",
    "input-password-confirm": "新しいパスワード（確認）",
    "submit": "リセット",
    "back-to-home": "ホーム画面に戻る"
  },
  "validation": {
    "email-is-empty": "Eメールアドレスが空白です",
    "email-is-invalid": "Eメールアドレスを入力してください",
    "password-is-too-short": "パスワードは、8文字以上かつ「英文字、数字、記号」をそれぞれ１文字以上含めてください",
    "password-is-invalid": "パスワードに、無効な文字が含まれています",
    "password-is-insecure": "パスワードには、「英文字、数字、記号」をそれぞれ１文字以上含めてください",
    "password-is-not-same": "入力されたパスワードが、一致していません",
    "first-name-is-invalid": "姓を入力してください",
    "last-name-is-invalid": "名を入力してください",
    "user-name-is-invalid": "名前を入力してください",
    "card-number-length-is-not-in-the-range": "カード番号には、14桁〜19桁の「数字」を入力してください",
    "card-number-is-invalid": "カード番号に、無効な文字が含まれています",
    "card-expiry-length-is-not-in-the-range": "カード有効期限が、無効です",
    "card-expiry-is-invalid": "カード有効期限に、無効な文字が含まれています",
    "card-holder-name-length-is-not-in-the-range": "カード名義人が、空白です",
    "card-holder-name-is-invalid": "カード名義人に、無効な文字が含まれています"
  },
  "error-message": {
    "title": "エラー",
    "app": {
      "Network Error": "ネットワークエラー",
      "userfront-invalid-argument": "指定引数がおかしい",
      "userfront-record-not-found": "レコードがみつからない",
      "userfront-duplicate-key": "重複キー",
      "userfront-not-available": "実行不可",
      "userfront-execute-api": "API実行",
      "userfront-internal": "内部エラー"
    },
    "auth": {
      "federated-signin-error": "エラーが発生しました.",
      "password-not-support": "Eメールアドレスまたはパスワードが正しくありません.",
      "verify-action-failure": "エラーが発生しました.",
      "sign-in-error": "サインインに失敗しました.",
      "sign-out-error": "サインアウトに失敗しました.",
      "fetch-user-error": "アカウント情報の取得に失敗しました.",
      "delete-user-error": "退会処理中にエラーが発生しました.",
      "auth-invalid-email": "Eメールアドレスの入力が正しくありません.",
      "auth-app-deleted": "インスタンスが削除されているため、ご利用できません.",
      "auth-app-not-authorized": "API認証エラー",
      "auth-argument-error": "引数が正しくありません.",
      "auth-invalid-api-key": "APIキーが無効です.",
      "auth-invalid-user-token": "トークンが無効です.",
      "auth-invalid-tenant-id": "テナントIDが無効です.",
      "auth-network-request-failed": "ネットワークエラー",
      "auth-operation-not-allowed": "この操作は許可されていません.",
      "auth-requires-recent-login": "サインインを再実行してください.",
      "auth-too-many-requests": "リクエストが多すぎます.",
      "auth-unauthorized-domain": "ドメイン認証エラー",
      "auth-user-disabled": "ユーザーは無効です.",
      "auth-user-token-expired": "トークンの有効期限切れ",
      "auth-web-storage-unsupported": "ブラウザが使用要件を満たしていません.",
      "auth-user-not-found": "入力内容に合致するユーザーは登録されていません.",
      "auth-wrong-password": "Eメールアドレスまたはパスワードが正しくありません."
    },
    "signup": {
      "signup-send-email-link-error": "リンクが無効です.",
      "signup-email-not-found": "Eメールアドレスが見つかりません.",
      "signup-email-link-invalid": "リンクが無効です.",
      "signup-signing-email-link-error": "リンクが無効です.",
      "auth-expired-action-code": "このリンクは無効です.",
      "auth-invalid-email": "Eメールアドレスの入力が正しくありません.",
      "auth-user-disabled": "ユーザーは無効です.",
      "auth-argument-error": "引数が正しくありません.",
      "auth-missing-android-pkg-name": "Androidアプリのパッケージ名が設定されていません.",
      "auth-missing-continue-uri": "コールバックURLが設定されていません.",
      "auth-missing-ios-bundle-id": "iOSアプリのBundle IDが設定されていません.",
      "auth-invalid-continue-uri": "コールバックURLの設定が正しくありません.",
      "auth-unauthorized-continue-uri": "認証されていないコールバックURLです."
    },
    "reauth": {
      "auth-invalid-password": "パスワードの確認に失敗しました."
    },
    "etc-error": "不明なエラー"
  },
  "not-found": "お探しのページは見つかりませんでした."
}