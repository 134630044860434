import * as dayjs from 'dayjs'
import 'dayjs/locale/ja'
import log from 'loglevel'
import * as React from 'react'
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'

// mui
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

// mui/x-date-picker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

// src
import { DotsIcon, StopIcon } from '~src/components/icons'
import { useLayout } from '~src/components/layouts/base'
import { ErrorInfoMessage } from '~src/functionals/error'
import { useTransitUsages } from '~src/hooks/transit-usages'
import { FareAttributes, PageInfo, TransitEvent, TransitUsage } from '~src/models/apis/private'
import { ErrorInfo } from '~src/models/apis/util'
import { useAppDispatch } from '~src/stores'
import { actions } from '~src/stores/slices/error'

type RenderItemProps = {
  item?: TransitUsage
  onOpen: (item: TransitUsage | undefined) => void;
}

function RenderItem(props: RenderItemProps) {
  const { t } = useTranslation()
  const { item, onOpen } = props

  const transited = dayjs(item?.transitedAt)
  const date = transited.format(t('recents.date-format'))
  const subtitle = item?.agencyName
  const brandName = item?.creditCard ? item?.creditCard.brandName : ''
  const fareText = item?.fare.fareStatus === 'FIXED' && item?.fare.text ? item?.fare.text : '-'
  const testChip = item?.eventEnv === 'test' ? <Chip label={"TEST"} size={"small"} sx={{ ml: 2, mb: 0.5 }} /> : <></>

  function parseEvent(event?: TransitEvent) {
    if (event) {
      const time = dayjs(event.transitedAt).format('HH:mm')
      return {
        time,
        stopName: event.stop.name
      }
    }
  }
  const eventStart = parseEvent(item?.events.filter(it => it.usage === 'ENTRANCE')[0])
  const eventEnd = parseEvent(item?.events.filter(it => it.usage === 'EXIT')[0])

  function itemClick() {
    onOpen(item)
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography component="div" color="text.secondary" sx={{ mx: 1 }}>
        {date}
        {testChip}
      </Typography>
      <Card variant="outlined" onClick={itemClick}>
        <CardActionArea>
          <CardContent sx={{ px: 2, py: 1 }}>
            <Typography component="div" variant="body1">
              {subtitle}
            </Typography>
            <Grid container>
              {eventStart && <Grid item container spacing={2} alignItems="center">
                <Grid item xs={2} sx={{ textAlign: 'right' }}><Typography variant="body2">{eventStart.time}</Typography></Grid>
                <Grid item xs={1.5} sx={{ textAlign: 'center' }}><StopIcon /></Grid>
                <Grid item xs><Typography variant="body2">{eventStart.stopName}</Typography></Grid>
              </Grid>}
              {false && <Grid item container spacing={2} alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={1.5} sx={{ textAlign: 'center' }}><DotsIcon /></Grid>
                <Grid item xs></Grid>
              </Grid>}
              {eventEnd && <Grid item container spacing={2} alignItems="center">
                <Grid item xs={2} sx={{ textAlign: 'right' }}><Typography variant="body2">{eventEnd.time}</Typography></Grid>
                <Grid item xs={1.5} sx={{ textAlign: 'center' }}><StopIcon /></Grid>
                <Grid item xs><Typography variant="body2">{eventEnd.stopName}</Typography></Grid>
              </Grid>}
              {!eventStart && !eventEnd && <Grid item container spacing={2} alignItems="center">
                <Grid item xs={2} sx={{ textAlign: 'right' }}><Typography variant="body2">{dayjs(item?.transitedAt).format('HH:mm')}</Typography></Grid>
                <Grid item xs={1.5} sx={{ textAlign: 'center' }}></Grid>
                <Grid item xs></Grid>
              </Grid>}
              <Grid item container spacing={2} alignItems="center">
                <Grid item xs></Grid>
                <Grid item xs={2} sx={{ textAlign: 'center' }}><Typography variant="body2">{brandName}</Typography></Grid>
                <Grid item xs={2} sx={{ textAlign: 'right' }}><Typography variant="body1" sx={{ fontWeight: 'bold' }}>{fareText}</Typography></Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  )
}

type RenderErrorProps = {
  err: ErrorInfo
}

function RenderError(props: RenderErrorProps) {
  const { err } = props
  // if (err.code === 'userfront/record-not-found') {
  return (
    <Stack sx={{ width: '100%' }}>
      <Alert severity="error">{ErrorInfoMessage(err)}</Alert>
    </Stack>
  )
  // }
  // return <></>
}

function RenderNodata() {
  const { t } = useTranslation()
  return (
    <Stack sx={{ width: '100%' }}>
      <Alert severity="info">{t('recents.no-record')}</Alert>
    </Stack>
  )
}

function RenderLoading() {
  return (
    <Box sx={{ p: 1 }}>
      <LinearProgress />
      {false && <CircularProgress />}
    </Box>
  )
}

type RenderItemModalProps = {
  item?: TransitUsage;
  open: boolean;
  onClose: () => void;
  onReceipt: (item?: TransitUsage | undefined) => void;
}

function RenderItemModal(props: RenderItemModalProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { item, open, onClose, onReceipt } = props

  const transited = dayjs(item?.transitedAt)
  const transitDate = transited.format(t('recents.date-format'))
  const transitTime = dayjs(item?.transitedAt).format('HH:mm')
  const transitNo = item?.transitNo
  const agencyName = item?.agencyName

  function parseEvent(event?: TransitEvent) {
    if (event) {
      const time = dayjs(event.transitedAt).format('HH:mm')
      return {
        time,
        stopName: event.stop.name
      }
    }
  }
  const eventStart = parseEvent(item?.events.filter(it => it.usage === 'ENTRANCE')[0])
  const eventEnd = parseEvent(item?.events.filter(it => it.usage === 'EXIT')[0])

  function parseFareAttribute(fareAttributes?: FareAttributes) {
    if (fareAttributes) {
      let attributesText = ''
      if (fareAttributes.adult) {
        const adult = t('recents-dialog.attributes-adult') + ' : ' + fareAttributes.adult.toString()
        attributesText = (attributesText ? attributesText + ', ' : '') + adult
      }
      if (fareAttributes.adultDiscount) {
        const adultDiscount = t('recents-dialog.attributes-adult-discount') + ' : ' + fareAttributes.adultDiscount.toString()
        attributesText = (attributesText ? attributesText + ', ' : '') + adultDiscount
      }
      if (fareAttributes.child) {
        const child = t('recents-dialog.attributes-child') + ' : ' + fareAttributes.child.toString()
        attributesText = (attributesText ? attributesText + ', ' : '') + child
      }
      if (fareAttributes.childDiscount) {
        const childDiscount = t('recents-dialog.attributes-child-discount') + ' : ' + fareAttributes.childDiscount.toString()
        attributesText = (attributesText ? attributesText + ', ' : '') + childDiscount
      }
      return attributesText
    }
    return ''
  }
  const fareAttributeText = parseFareAttribute(item?.fare.fareAttributes)

  const fareText = item?.fare.fareStatus === 'FIXED' && item?.fare.text ? item?.fare.text : '-'
  const remarks = item?.fare.remarks
  const agencyTel = item?.agencyTel
  const brandName = item?.creditCard ? item?.creditCard?.brandName : ''
  const maskedNumber = item?.creditCard ? item?.creditCard?.maskedNumber : ''

  const tableItems = [
    {
      key: 'recents-dialog.transit-id',
      title: <Typography variant="body2">{t('recents-dialog.transit-id')}</Typography>,
      value: <Typography variant="body2">{transitNo}</Typography>
    },
    {
      key: 'recents-dialog.agency-name',
      title: <Typography variant="body2">{t('recents-dialog.agency-name')}</Typography>,
      value: <Typography variant="body2">{agencyName}</Typography>
    },
    {
      key: 'recents-dialog.entrance',
      title: <Typography variant="body2">{t('recents-dialog.entrance')}</Typography>,
      value: (eventStart && <Grid item container spacing={0} alignItems="left" direction="column">
        <Grid item xs={3}><Typography variant="body2">{eventStart.time}</Typography></Grid>
        <Grid item xs><Typography variant="body2">{eventStart.stopName}</Typography></Grid>
      </Grid>) ||
        (!eventStart && !eventEnd && <Grid item container spacing={0} alignItems="left" direction="column">
          <Grid item xs={3}><Typography variant="body2">{transitTime}</Typography></Grid>
          <Grid item xs></Grid>
        </Grid>)
    },
    {
      key: 'recents-dialog.exit',
      title: <Typography variant="body2">{t('recents-dialog.exit')}</Typography>,
      value: eventEnd && <Grid item container spacing={0} alignItems="left" direction="column">
        <Grid item xs={3}><Typography variant="body2">{eventEnd.time}</Typography></Grid>
        <Grid item xs><Typography variant="body2">{eventEnd.stopName}</Typography></Grid>
      </Grid>
    },
    {
      key: 'recents-dialog.attributes',
      title: <Typography variant="body2">{t('recents-dialog.attributes')}</Typography>,
      value: <Typography variant="body2">{fareAttributeText}</Typography>
    },
    {
      key: 'recents-dialog.fared',
      title: <Typography variant="body2">{t('recents-dialog.fare')}</Typography>,
      value: <Typography variant="body2">{fareText}</Typography>
    },
    {
      key: 'recents-dialog.remarks',
      title: <Typography variant="body2">{t('recents-dialog.remarks')}</Typography>,
      value: <Typography variant="body2">{remarks}</Typography>
    },
    {
      key: 'recents-dialog.inquiry-tel',
      title: <Typography variant="body2">{t('recents-dialog.inquiry-tel')}</Typography>,
      value: <Link href={'tel:' + agencyTel} variant="body2">{agencyTel}</Link>
    },
    {
      key: 'recents-dialog.credit-card',
      title: <Typography variant="body2">{t('recents-dialog.credit-card')}</Typography>,
      value: <Grid item container spacing={0} alignItems="left">
        <Grid item xs={4}><Typography variant="body2">{brandName}</Typography></Grid>
        <Grid item xs><Typography variant="body2">{maskedNumber}</Typography></Grid>
      </Grid>
    }
  ]

  return (
    <Dialog open={open} fullWidth={true} onClose={onClose}>
      <DialogTitle>{t('recents-dialog.title')}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} variant="outlined">
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align='center'><Typography variant="body1">{transitDate}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableItems.map((row) => {
                return <TableRow key={row.key} sx={{ '&:last-child td, &:last-child th': { borderLeftWidth: 0, borderTopWidth: 0, borderBottomWidth: 0 } }}>
                  <TableCell component="th" scope="row" sx={{ width: 120, borderRightWidth: 1, borderRightColor: theme.palette.divider, borderRightStyle: 'solid' }}>{row.title}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Grid item container spacing={0} alignItems="center">
          <Grid item sx={{ textAlign: 'center' }} xs={6}>
            <Button variant="contained" size="large" onClick={() => { onReceipt(item) }}>{t('receipt.title')}</Button>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }} xs={6}>
            <Button variant="contained" size="large" onClick={onClose}>{t('recents-dialog.button-close')}</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

type MonthSelection = 'this' | 'last' | 'last2' | 'select'

export function Recents() {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()

  const [month, setMonth] = useState<MonthSelection>('this')
  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [selectValue, setSelectValue] = useState<dayjs.Dayjs | null>(() => dayjs())
  const [acceptValue, setAcceptValue] = useState<dayjs.Dayjs>(() => dayjs().startOf('month'))
  const monthSet = useMemo(() => {
    const dmax = dayjs().startOf('month')
    const dmin = dmax.add(-2, 'year')
    let d1: dayjs.Dayjs = dayjs().startOf('month')
    switch (month) {
      case 'this':
        break
      case 'last':
        d1 = d1.add(-1, 'month')
        break
      case 'last2':
        d1 = d1.add(-2, 'month')
        break
      case 'select':
        // 選択した月をセット
        d1 = acceptValue.startOf('month')
        break
    }
    const d2 = d1.add(1, 'month')
    const title = d1.format(t('recents.month-format'))
    return { title, start: d1, end: d2, max: dmax, min: dmin }
  }, [i18n.language, month, acceptValue])

  const handleMonth = (event: React.MouseEvent<HTMLElement>, newMonth: MonthSelection | null) => {
    event.preventDefault()
    if (newMonth != null && newMonth !== 'select') {
      setMonth(newMonth)

      const d1: dayjs.Dayjs = dayjs().startOf('month')
      switch (newMonth) {
        case 'this':
          setSelectValue(d1)
          break
        case 'last':
          setSelectValue(d1.add(-1, 'month'))
          break
        case 'last2':
          setSelectValue(d1.add(-2, 'month'))
          break
      }
    }
  }

  // データ取得
  const {
    data,
    isValidating,
    errorInfo,
    size,
    setSize,
    mutate
  } = useTransitUsages(monthSet.start.format(), monthSet.end.format(), 15)

  let dataLength = 0
  let pageInfo: PageInfo | undefined
  let fareText: string | undefined
  if (data && data.length > 0) {
    const lastOne = data[data.length - 1]
    if (lastOne) {
      const connection = lastOne.user.transitUsages
      pageInfo = connection.pageInfo
      fareText = connection.totalFare.text
    }
    data.forEach(it => {
      dataLength += it.user.transitUsages.edges.length
    })
  }
  const hasMoreData = (
    !isValidating &&
    !errorInfo &&
    pageInfo != null &&
    pageInfo.hasPreviousPage
  )
  const fetchMoreData = () => {
    setTimeout(() => {
      setSize(size + 1)
    }, 1500)
  }

  useEffect(() => {
    if (errorInfo) {
      dispatch(actions.push({
        source: 'app',
        code: errorInfo.code,
        err: errorInfo.err
      }))
    }
  }, [errorInfo])

  function renderEndMessage() {
    // fetching
    if (dataLength <= 0 && isValidating) return <RenderLoading />
    // error
    if (dataLength <= 0 && errorInfo) return <RenderError err={errorInfo} />
    // no data
    if (dataLength <= 0) return <RenderNodata />
    return <></>
  }

  const divRef = useRef<HTMLDivElement | null>(null)
  const [contentHeight, setContentHeight] = useState(200)
  const layout = useLayout()

  useLayoutEffect(() => {
    function updateSize() {
      const main = layout.mainRef()
      const div = divRef.current
      if (main && div) {
        const h = main.offsetTop + main.clientHeight - div.offsetTop - 32
        setContentHeight(h)
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const [modalOpen, setModalOpen] = useState(false)
  const [selectItem, setSelectItem] = useState<TransitUsage | undefined>(undefined)

  const onModalOpen = (item: TransitUsage | undefined) => {
    if (item) {
      setSelectItem(item)
      setModalOpen(true)
    }
  }

  const onModalClose = () => {
    setModalOpen(false)
  }

  const onReceiptOpen = (item: TransitUsage | undefined) => {
    navigate("../receipt", { state: item })
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
    >
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* 年月選択 */}
        <ToggleButtonGroup
          exclusive
          value={month}
          onChange={handleMonth}
          fullWidth={true}
          sx={{ pl: 2, pr: 2, mb: 2 }}
        >
          <ToggleButton value="this">{t('recents.month-this')}</ToggleButton>
          <ToggleButton value="last">{t('recents.month-last')}</ToggleButton>
          <ToggleButton value="last2">{t('recents.month-last2')}</ToggleButton>
          <ToggleButton value="select" sx={{ width: 'auto' }} onClick={(event) => {
            event.preventDefault()
            // open date picker
            setSelectOpen(true)
          }}>
            <ArrowDropDownIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <MobileDatePicker
          views={['year', 'month']}
          minDate={monthSet.min}
          maxDate={monthSet.max}
          open={selectOpen}
          onClose={() => {
            log.debug('app/recents - onClose')
            setSelectOpen(false)
          }}
          value={selectValue}
          onChange={(newValue) => {
            log.debug('app/recents - onChange', newValue?.toISOString())
            setSelectValue(newValue)
          }}
          onAccept={(value) => {
            log.debug('app/recents - onAccept', value?.toISOString())
            // 確定
            if (value) {
              setAcceptValue(value)
              setMonth('select')
            }
          }}
          onError={(reason, value) => {
            log.debug('app/recents - onError', reason, value)
          }}
          renderInput={(params) => <></>}
          reduceAnimations={true}
          toolbarTitle={t('recents.select-date')}
          DialogProps={{
            onClose: (event, reason) => {
              // backdropClickでもなぜかonAcceptが呼ばれるのでここでcloseする
              log.debug('app/recents - DialogProps.onClose', reason)
              setSelectOpen(false)
            }
          }}
        />

        {/* 年月表示 + 合計金額表示 */}
        <Typography component="h1" variant="h4">
          {monthSet.title}
        </Typography>

        {/* 合計金額表示 */}
        <Paper variant="outlined" sx={{ mt: 1, mb: 2, p: 2, width: '100%' }}>
          <Grid container alignItems="center">
            <Grid item>
              <Typography component="div" variant="h6" color="text.secondary">
                {t('recents.monthly-total-text')}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography component="div" variant="h6" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                {fareText}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        {/* 履歴表示 */}
        <div ref={divRef} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>

              <InfiniteScroll
                dataLength={dataLength}
                next={fetchMoreData}
                hasMore={hasMoreData}
                height={contentHeight}
                loader={<RenderLoading />}
                endMessage={renderEndMessage()}
                refreshFunction={() => {
                  // refresh
                  mutate().catch(_ => { })
                }}
                pullDownToRefresh
                pullDownToRefreshThreshold={100}
                pullDownToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8595; {t('recents.pull-down-refresh')}</h3>
                }
                releaseToRefreshContent={
                  <h3 style={{ textAlign: 'center' }}>&#8593; {t('recents.release-refresh')}</h3>
                }
              >
                {(data || []).map((result, index) => {
                  return result.user.transitUsages.edges.map((it, index) => (
                    <RenderItem key={index} item={it.node} onOpen={onModalOpen} />
                  ))
                })}
              </InfiniteScroll>

            </Grid>
          </Grid>
        </div>
        <RenderItemModal open={modalOpen} item={selectItem} onClose={onModalClose} onReceipt={onReceiptOpen} />
      </Box>

    </LocalizationProvider>
  )
}
