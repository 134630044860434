import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export function Download (html: HTMLElement | null, filename: string) {
  const originalElements: { span: HTMLElement, parent: Node, originalInput: HTMLInputElement | HTMLTextAreaElement }[] = []

  try {
    // テキストフィールドを取得
    const textFields = html?.querySelectorAll('textarea, input[type="text"]')

    textFields?.forEach((field, index) => {
      try {
        console.log(`Processing field #${index + 1}`)
        console.log('Original value:', (field as HTMLInputElement | HTMLTextAreaElement).value)

        // xが来たら無視する
        if ((field as HTMLInputElement | HTMLTextAreaElement).value === 'x') {
          return
        }

        // 改行を <br> に置換
        const value = (field as HTMLInputElement | HTMLTextAreaElement).value.replace(/\n/g, '<br>')
        console.log('Replaced value:', value)

        // span 要素を作成して置換
        const span = document.createElement('span')
        span.innerHTML = value
        span.style.whiteSpace = 'pre-wrap'
        span.style.wordWrap = 'break-word'
        span.style.width = '100%' // フィールドと同じ幅に設定
        span.style.display = 'block' // ブロック要素として扱う

        // 元の field と親ノードを保存
        originalElements.push({ span, parent: field.parentNode!, originalInput: field as HTMLInputElement | HTMLTextAreaElement })

        field.parentNode?.replaceChild(span, field)
        console.log(`Field #${index + 1} replaced successfully`)
      } catch (innerError) {
        console.error(`Error processing field #${index + 1}:`, innerError)
      }
    })

    // html2canvasを使用してPDFを生成
    html2canvas(html!, {
      scale: 2.5,
      useCORS: true,
      allowTaint: true,
      logging: true,
      windowWidth: document.documentElement.scrollWidth, // 表示されている画面幅をそのまま使用
      windowHeight: document.documentElement.scrollHeight // 表示されている高さをそのまま使用
    }).then((canvas) => {
      try {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()
        const imgWidth = pdfWidth * 0.75 // adjusting image width to fit A4 paper
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const xOffset = (pdfWidth - imgWidth) / 2
        const yOffset = (pdfHeight - imgHeight) / 2

        pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight)
        pdf.save(filename + '.pdf')
        console.log('PDF generated and saved successfully')

        // 元の input タグに戻す
        originalElements.forEach(({ span, parent, originalInput }) => {
          if (parent.contains(span)) {
            parent.replaceChild(originalInput, span)
            console.log('Input field restored successfully')
          } else {
            console.warn('The node is not a child of its parent anymore')
          }
        })
      } catch (canvasError) {
        console.error('Error generating PDF:', canvasError)
        originalElements.forEach(({ span, parent, originalInput }) => {
          if (parent.contains(span)) {
            parent.replaceChild(originalInput, span)
          }
        })
      }
    }).catch((canvasError) => {
      console.error('Error during html2canvas processing:', canvasError)
      originalElements.forEach(({ span, parent, originalInput }) => {
        if (parent.contains(span)) {
          parent.replaceChild(originalInput, span)
        }
      })
    })
  } catch (error) {
    console.error('An error occurred in the Download function:', error)
    originalElements.forEach(({ span, parent, originalInput }) => {
      if (parent.contains(span)) {
        parent.replaceChild(originalInput, span)
      }
    })
  }
}
