import { GraphQLAPI, GraphQLResult } from '@aws-amplify/api-graphql'
import { gql } from 'graphql-request'
import log from 'loglevel'

export const graphqlEndpoint = process.env.USERFRONTAPI_GRAPHQL_PRIVATE!

// ----------------------------------------------------------------
// types

export type PageInfo = {
  hasNextPage: boolean
  endCursor: string
  hasPreviousPage: boolean
  startCursor: string
}

// ----------------------------------------------------------------
// query: user

export type UserAgreement = {
  id: string
  tosVersion: string
  createdAt: string
}

export type CreditCard = {
  id: string
  systemCardId: string
  brandName: string
  maskedNumber: string
  isApproval: boolean
  createdAt: string
}

export type UserAccount = {
  id: string
  email: string
  userName: string
  kana?: string
  allowExtraEmails: boolean
  agreement?: UserAgreement
}

export type FetchUserResult = {
  user?: UserAccount
}

export async function fetchUser (userId: string) {
  const query = gql`
query FetchUser($userId: ID!) {
  user(uid: $userId) {
    id
    email
    userName
    kana
    allowExtraEmails
    agreement{
      id
      tosVersion
      createdAt
    }
  }
}
`
  try {
    log.debug('gql - fetchUser', { userId })
    const response = await GraphQLAPI.graphql<FetchUserResult>({
      query,
      variables: { userId },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<FetchUserResult>
    log.debug('gql - fetchUser /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - fetchUser /err', err)
    throw err
  }
}

// ----------------------------------------------------------------
// query: user/transitUsages

export type FareAttributes = {
  adult?: number // int
  adultDiscount?: number // int
  child?: number // int
  childDiscount?: number // int
}

export type Fare = {
  currencty: string
  fareStatus: 'UNKNOWN' | 'UNSOLVED' | 'FIXED' | 'INVALIDATED'
  value?: number // float64
  text?: string
  fareAttributes?: FareAttributes
  remarks: string
}

export type Stop = {
  id: string
  name: string
}

export type TransitEvent = {
  id: string
  transitType: 'UNKNOWN' | 'BUS'
  transitedAt: string
  usage: 'UNKNOWN' | 'ENTRANCE' | 'EXIT'
  stop: Stop
}

export type Customer = {
  name: string
}

export type TransitUsage = {
  id: string
  agencyName: String
  agencyTel: String
  serviceInstanceId: String
  transitNo: String
  transitType: 'UNKNOWN' | 'BUS'
  transitedAt: string
  fare: Fare
  eventEnv: String
  creditCard?: CreditCard
  events: TransitEvent[]
  customer?: Customer
}

export type TransitUsageConnection = {
  edges: {
    node: TransitUsage
  }[]
  pageInfo: PageInfo
  totalCount: string // int64 string
  totalFare: Fare
}

export type FetchTransitUsagesResult = {
  user: {
    transitUsages: TransitUsageConnection
  }
}

export type FetchTransitUsagesInput = {
  first?: number
  after?: string
  last?: number
  before?: string
  from: string
  to: string
}

export async function fetchTransitUsages (userId: string, input: FetchTransitUsagesInput) {
  const query = gql`
query FetchTransitUsages(
  $userId: ID!
  $first: Int
  $after: String
  $last: Int
  $before: String
  $from: Time!
  $to: Time!
) {
  user(uid: $userId) {
    transitUsages(
      first: $first
      after: $after
      last: $last
      before: $before
      from: $from
      to: $to
    )
    {
      edges{
        node{
          id
          agencyName
          agencyTel
          serviceInstanceId
          transitNo          
          transitType
          transitedAt
          fare{
            currency
            fareStatus
            value
            text
            remarks
            fareAttributes{
              adult
              adultDiscount
              child
              childDiscount
            }
          }
          eventEnv
          creditCard{
            id
            systemCardId
            brandName
            maskedNumber
            isApproval
            createdAt
          }
          events{
            id
            transitType
            transitedAt
            usage
            stop{
              id
              name
            }
          }
          customer {
            name
          }
        }
      }
      pageInfo{
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
      totalFare{
        currency
        fareStatus
        value
        text
        remarks
        fareAttributes{
          adult
          adultDiscount
          child
          childDiscount
        }
      }
    }
  }
}
`
  try {
    log.debug('gql - fetchTransitUsages', input)
    const response = await GraphQLAPI.graphql<FetchTransitUsagesResult>({
      query,
      variables: { userId, ...input },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<FetchTransitUsagesResult>
    log.debug('gql - fetchTransitUsages /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - fetchTransitUsages /err', err)
    throw err
  }
}

// ----------------------------------------------------------------
// query: user/creditCards

export type CreditCardConnection = {
  edges: {
    node: CreditCard
  }[]
  pageInfo: PageInfo
  totalCount: string // int64 string
}

export type FetchCreditCardsResult = {
  user: {
    creditCards: CreditCardConnection
  }
}

export type FetchCreditCardsInput = {
  first?: number
  after?: string
  last?: number
  before?: string
}

export async function fetchCreditCards (userId: string, input: FetchCreditCardsInput) {
  const query = gql`
query FetchCreditCards(
  $userId: ID!
  $first: Int
  $after: String
  $last: Int
  $before: String
) {
  user(uid: $userId) {
    creditCards(
      first: $first
      after: $after
      last: $last
      before: $before
    )
    {
      edges{
        node{
          id
          systemCardId
          brandName
          maskedNumber
          isApproval
          createdAt
        }
      }
      pageInfo{
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
}
`
  try {
    log.debug('gql - fetchCreditCards', input)
    const response = await GraphQLAPI.graphql<FetchCreditCardsResult>({
      query,
      variables: { userId, ...input },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<FetchCreditCardsResult>
    log.debug('gql - fetchCreditCards /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - fetchCreditCards /err', err)
    throw err
  }
}

// ----------------------------------------------------------------
// mutation: addUser

export type AddUserInput = {
  uid: string
  email: string
  userName: string
  kana?: string
  allowExtraEmails: boolean
}

export type AddUserResult = {
  addUser: {
    user: UserAccount
  }
}

export async function addUser (input: AddUserInput) {
  const query = gql`
mutation AddUser($input: AddUserInput!) {
  addUser(input: $input) {
    user {
      id
      email
      userName
      kana
      allowExtraEmails
    }
  }
}
`
  try {
    log.debug('gql - addUser', input)
    const response = await GraphQLAPI.graphql<AddUserResult>({
      query,
      variables: { input },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<AddUserResult>
    log.debug('gql - addUser /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - addUser /err', err)
    throw err
  }
}

// ----------------------------------------------------------------
// mutation: updateUser

export type UpdateUserInput = {
  userId: string
  uid: string
  email?: string
  userName?: string
  kana?: string
  allowExtraEmails?: boolean
}

export type UpdateUserResult = {
  updateUser: {
    user: UserAccount
  }
}

export async function updateUser (input: UpdateUserInput) {
  const query = gql`
mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      email
      userName
      kana
      allowExtraEmails
    }
  }
}
`
  try {
    log.debug('gql - updateUser', input)
    const response = await GraphQLAPI.graphql<UpdateUserResult>({
      query,
      variables: { input },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<UpdateUserResult>
    log.debug('gql - updateUser /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - updateUser /err', err)
    throw err
  }
}

// ----------------------------------------------------------------
// mutation: deleteUser

export type DeleteUserInput = {
  userId: string
  uid: string
}

export type DeleteUserResult = {
  deleteUser: {
    user: UserAccount
  }
}

export async function deleteUser (input: DeleteUserInput) {
  const query = gql`
mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    user {
      id
      email
      userName
      kana
      allowExtraEmails
    }
  }
}
`
  try {
    log.debug('gql - deleteUser', input)
    const response = await GraphQLAPI.graphql<DeleteUserResult>({
      query,
      variables: { input },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<DeleteUserResult>
    log.debug('gql - deleteUser /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - deleteUser /err', err)
    throw err
  }
}

// ----------------------------------------------------------------
// mutation: addCreditCard

export type AddCreditCardInput = {
  userId: string
  systemCardId: string
}

export type AddCreditCardResult = {
  addCreditCard: {
    creditCard: CreditCard
  }
}

export async function addCreditCard (input: AddCreditCardInput) {
  const query = gql`
mutation AddCreditCard($input: AddCreditCardInput!) {
  addCreditCard(input: $input) {
    creditCard {
      id
      systemCardId
      brandName
      maskedNumber
      isApproval
      createdAt
    }
  }
}
`
  try {
    log.debug('gql - addCreditCard', input)
    const response = await GraphQLAPI.graphql<AddCreditCardResult>({
      query,
      variables: { input },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<AddCreditCardResult>
    log.debug('gql - addCreditCard /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - addCreditCard /err', err)
    throw err
  }
}

// ----------------------------------------------------------------
// mutation: deleteCreditCard

export type DeleteCreditCardInput = {
  id: string
}

export type DeleteCreditCardResult = {
  deleteCreditCard: {
    creditCard: CreditCard
  }
}

export async function deleteCreditCard (input: DeleteCreditCardInput) {
  const query = gql`
mutation DeleteCreditCard($input: DeleteCreditCardInput!) {
  deleteCreditCard(input: $input) {
    creditCard {
      id
      systemCardId
      brandName
      maskedNumber
      isApproval
      createdAt
    }
  }
}
`
  try {
    log.debug('gql - deleteCreditCard', input)
    const response = await GraphQLAPI.graphql<DeleteCreditCardResult>({
      query,
      variables: { input },
      authMode: 'AWS_IAM'
    }) as GraphQLResult<DeleteCreditCardResult>
    log.debug('gql - deleteCreditCard /ok', response)
    return response.data!
  } catch (err) {
    log.error('gql - deleteCreditCard /err', err)
    throw err
  }
}
