import * as React from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

// reCAPTCHA
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

// mui
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// ./
import { useAppDispatch } from '~/src/stores'
import { actions } from '~/src/stores/slices/auth'
import { LinkWithQuery } from '~src/components/link-with-query'

// 参考情報:
// - autofill
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill

function SignInReCaptcha() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [emailErr, setEmailErr] = useState<string>()
  const [passwordErr, setPasswordErr] = useState<string>()
  const [showPassword, setShowPassword] = useState(false)

  const validateEmailAndSetErr = (text: string): boolean => {
    const invalid = text.length <= 0
    setEmailErr(invalid ? 'sign-in.invalid-email' : undefined)
    return invalid
  }

  const validatePasswordAndSetErr = (text: string): boolean => {
    const invalid = text.length <= 0
    setPasswordErr(invalid ? 'sign-in.invalid-password' : undefined)
    return invalid
  }

  const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    validateEmailAndSetErr(event.target.value)
  }

  const handlePasswordChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    validatePasswordAndSetErr(event.target.value)
  }

  const handleClickShowPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setShowPassword(!showPassword)
  }

  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email') as string | null ?? ''
    const password = data.get('password') as string | null ?? ''

    // validation check
    const emailInvalid = validateEmailAndSetErr(email)
    const passwordInvalid = validatePasswordAndSetErr(password)
    if (emailInvalid || passwordInvalid) {
      return
    }

    // reCAPTCHA
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }
    const token = await executeRecaptcha('login')

    // proceed login
    dispatch(actions.signIn({ email, password, token }))
  }, [executeRecaptcha])

  return (
    <>
      <Box
        sx={{
          marginTop: 4,
          marginBottom: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >

        {/* ロゴアイコン TBD :: 後で変更 */}
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>

        {/* タイトルメッセージ */}
        <Typography component="h1" variant="h5">
          {t('sign-in.title')}
        </Typography>

        {/* フォーム */}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

          {/* 入力欄 */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="signin-email"
                name="email"
                label={t('sign-up.input-email')}
                error={emailErr != null}
                helperText={emailErr ? t(emailErr) : undefined}
                onChange={handleEmailChanged}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                id="signin-password"
                name="password"
                label={t('sign-up.input-password')}
                error={passwordErr != null}
                helperText={passwordErr ? t(passwordErr) : undefined}
                onChange={handlePasswordChanged}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>

          {/* リンク: パスワード忘れた */}
          <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <LinkWithQuery variant="body2" to="../reset-password">
                {t('sign-in.goto-reset-password')}
              </LinkWithQuery>
            </Grid>
          </Grid>

          {/* サインイン */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('sign-in.submit')}
          </Button>

          {/* リンク: サインアップ */}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <LinkWithQuery variant="body2" to="../signup/new">
                {t('sign-in.goto-sign-up')}
              </LinkWithQuery>
            </Grid>
          </Grid>

          {/* ----------------------- */}
          {/* 
          <Divider sx={{ mt: 2, mb: 2 }}>{t('sign-in.social-idp-divider')}</Divider>
          */}

          {/* ソーシャルアカウントでログインする */}
          {/*
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<FcGoogle size="1.2em" />}
              >
                {t('sign-in.continue-with-google')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={
                  <IconContext.Provider
                    value={{
                      style: {
                        color: '#4267B2'
                      }
                    }}
                  >
                    <FaFacebookSquare size="1.2em" />
                  </IconContext.Provider>
                }
              >
                {t('sign-in.continue-with-facebook')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="outlined"
                startIcon={
                  <IconContext.Provider
                    value={{
                      style: {
                        color: '#1DA1F2'
                      }
                    }}
                  >
                    <FaTwitter size="1.2em" />
                  </IconContext.Provider>
                }
              >
                {t('sign-in.continue-with-twitter')}
              </Button>
            </Grid>
          </Grid>
          */}

        </Box>

      </Box>
    </>
  )
}

export function SignIn() {
  const { i18n } = useTranslation()
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}
      useEnterprise={parseBool(process.env.RECAPTCHA_USE_ENTERPRISE)}
      language={i18n.language}
    >
      <SignInReCaptcha />
    </GoogleReCaptchaProvider>
  )
}

function parseBool(str?: string): boolean {
  switch (str) {
    case '1':
    case 't':
    case 'T':
    case 'true':
    case 'TRUE':
    case 'True':
      return true
  }
  return false
}
