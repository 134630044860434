import { Button, Paper, ThemeProvider, createTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/system'
import * as dayjs from 'dayjs'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Download } from '~/src/functionals/download'
import { FareAttributes, TransitEvent, TransitUsage } from '~src/models/apis/private'

interface SubBoxProps {
  sx?: SxProps;
  children: React.ReactNode;
}

function SubBox ({ sx, children }: SubBoxProps) {
  return (
    <Box fontSize={'small'} sx={sx}>{children}</Box>
  )
}

//
export function Receipt () {
  const { t } = useTranslation()

  const location = useLocation()
  const [item] = useState<TransitUsage | undefined>(location.state as TransitUsage | undefined)

  const [doDownload, setDoDownload] = useState<boolean>(false)

  if (!item) {
    return <></>
  }

  const transited = dayjs(item?.transitedAt)
  const transitDate = transited.format(t('receipt.date-format'))

  // PDF Event
  useEffect(() => {
    if (doDownload) {
      const dom = document.getElementById('pdf-id')
      if (dom) {
        Download(dom, 'create')
      }
      setDoDownload(false)
    }
  }, [doDownload])

  // on download button
  const handleDownload = () => {
    setDoDownload(true)
  }

  const eventLayout = (events: TransitEvent[]): JSX.Element | null => {
    let content = ''

    for (let i = 0; i < events.length; i++) {
      if (events[i].usage === 'ENTRANCE') {
        content += events[i].stop.name
      }
    }

    for (let i = 0; i < events.length; i++) {
      if (events[i].usage === 'EXIT') {
        if (content.length > 0) {
          content += '\u00A0～\u00A0' // \u00A0 = &nbsp;
        }
        content += events[i].stop.name
      }
    }

    return <Box>{content}</Box>
  }

  const fareAttributeLayout = (fareAttributes: FareAttributes | undefined): JSX.Element | null => {
    let content = ''

    for (const key in fareAttributes) {
      let val = 0 as number | undefined
      let name = ''

      if (key === 'adult') {
        val = fareAttributes.adult
        name = t('receipt.fare-attributes.adult')
      } else if (key === 'child') {
        val = fareAttributes.child
        name = t('receipt.fare-attributes.child')
      } else if (key === 'adultDiscount') {
        val = fareAttributes.adultDiscount
        name = t('receipt.fare-attributes.adult-discount')
      } else if (key === 'childDiscount') {
        val = fareAttributes.childDiscount
        name = t('receipt.fare-attributes.child-discount')
      }

      if ((val ?? 0) > 0) { // 1人以上
        if (content.length > 0) {
          content += '\u00A0、\u00A0' // \u00A0 = &nbsp;
        }
        content += name + ' : ' + val // 大人 : 1
      }
    }

    return <Box>{content}</Box>
  }

  // Create a light theme
  const [lightTheme] = useState(createTheme({
    palette: {
      mode: 'light'
    }
  }))

  return (<Box
    sx={{
      mt: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: document.documentElement.scrollWidth * 0.9,
      height: document.documentElement.scrollHeight
    }}
  >

    <ThemeProvider theme={lightTheme}>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Grid container spacing={2} id="pdf-id">
          {/* 領収書 */}
          <Grid item xs={12}>
            <Typography component="h1" variant="h4">
              {t('receipt.title')}
            </Typography>
          </Grid>

          {/* 罫線 */}
          <Grid item xs={12}>
            <Box sx={{ border: 1, padding: 2 }}>
              <Grid container spacing={1}>
                {/* 宛名 x 様 */}
                <Grid item xs={3}>
                  <Box>{t('receipt.address')}：</Box>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    rows={2}
                    multiline
                    variant="standard"
                    size="small"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: '0.9em',
                        lineHeight: '1.5em',
                        height: '3em',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                        display: 'flex',
                        alignItems: 'center'
                      }
                    }}
                    placeholder={doDownload ? '' : t('receipt.placeholder')}
                    sx={{
                      borderBottom: 1,
                      backgroundColor: doDownload ? 'transparent' : 'lightyellow',
                      wordBreak: 'keep-all'
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box>{t('receipt.honorific')}</Box>
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{ marginTop: 2 }}>
                {/* 金額 */}
                <Grid item xs={5}>
                  <Box>{t('receipt.fare')}：</Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>{item.fare.text}</Box>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'end' }}>
                  <Typography fontSize={'small'}>{t('receipt.taxin')}</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={0} sx={{ marginTop: 2 }}>
                {/* 支払方法 */}
                <Grid item xs={5}>
                  <Box>{t('receipt.method')}：</Box>
                </Grid>
                <Grid item xs={7}>
                  <Box >{t('receipt.credit')}</Box>
                </Grid>
              </Grid>

              <Grid container spacing={0} sx={{ marginTop: 2 }}>
                {/* 商品情報 */}
                <Grid item xs={4}>
                  <Box>{t('receipt.bought')}：</Box>
                </Grid>
              </Grid>
              <Grid container sx={{ pt: 0 }}>
                <Box sx={{ border: 0, padding: 2 }}>
                  {/* 但し */}
                  <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} sx={{ borderBottom: 1 }}>
                      <SubBox sx={{ wordBreak: 'keep-all' }}>{t('receipt.word1')}：</SubBox>
                    </Grid>
                    <Grid item xs={8} sx={{ borderBottom: 1 }}>
                      <SubBox>{t('receipt.word2')}</SubBox>
                    </Grid>

                    {/* 区間 */}
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} sx={{ borderBottom: 1 }}>
                      <SubBox sx={{ wordBreak: 'keep-all' }}>{t('receipt.area')}：</SubBox>
                    </Grid>
                    <Grid item xs={8} sx={{ borderBottom: 1 }}>
                      <SubBox>{eventLayout(item.events)}</SubBox>
                    </Grid>

                    {/* 人数 */}
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} sx={{ borderBottom: 1 }}>
                      <SubBox sx={{ wordBreak: 'keep-all' }}>{t('receipt.people')}：</SubBox>
                    </Grid>
                    <Grid item xs={8} sx={{ borderBottom: 1 }}>
                      <SubBox>{fareAttributeLayout(item.fare.fareAttributes)}</SubBox>
                    </Grid>

                    {/* ご利用日 */}
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} sx={{ borderBottom: 1 }}>
                      <SubBox sx={{ wordBreak: 'keep-all' }}>{t('receipt.usedate')}：</SubBox>
                    </Grid>
                    <Grid item xs={8} sx={{ borderBottom: 1 }}>
                      <SubBox>{transitDate}</SubBox>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>

          {/* 事業者 */}
          <Grid item xs={12}>
            <Grid container justifyContent={'flex-end'}>
              <Grid item>
                <Typography align='right'>{item.customer?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* 注意書き, ID */}
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <Typography fontSize={10}>{t('receipt.word3')}</Typography>
              </Grid>

              {/* ID */}
              <Grid item mt={0.5}>
                <Typography fontSize={10}>{t('receipt.transit-id')}: {item.transitNo}</Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Paper>
    </ThemeProvider>

    {/* PDF外 */}
    <Grid container spacing={2} mt={2} id="hide-element">
      <Grid item xs={12}>
        <Button variant="outlined" fullWidth size="large" onClick={handleDownload}>{t('receipt.download')}</Button>
      </Grid>
    </Grid>
  </Box >)
}
