import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Navigate, Outlet, Route,
  Routes, useNavigate,
  useSearchParams
} from 'react-router-dom'
import { ErrorSnackbar } from './components/error-snackbar'

// src
import { Layout } from './components/layouts/base'
import { NavLayout } from './components/layouts/nav'
import { LoadingProgress } from './components/loading-progress'
import { Theme } from './components/theme'
import { RequireAuth, SkipSigning } from './functionals/auth'
import { changeLanguage } from './locales/i18n'
import { ChangeEmail } from './pages/app/accounts/change-email'
import { ChangePassword } from './pages/app/accounts/change-password'
import { EditAccount } from './pages/app/accounts/edit-account'
import { RegistCardResult } from './pages/app/card/regist-card-result'
import { Cards } from './pages/app/cards'
import { MyAccount } from './pages/app/my-account'
import { Receipt } from './pages/app/receipt'
import { Recents } from './pages/app/recents'
import { AuthActionCallback } from './pages/auth/action-callback'
import { NotFound } from './pages/not-found'
import { ResetPassword } from './pages/reset-password'
import { SignIn } from './pages/signin'
import { SignUpComplete } from './pages/signup/complete'
import { SignUpNew } from './pages/signup/new'
import { SignUpSending } from './pages/signup/sending'
import { SignUpVerify } from './pages/signup/verify'
import { useAppDispatch } from './stores'
import { actions as routerActions } from './stores/slices/router'

export function App() {
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(routerActions.bind({
      navigate
    }))
  }, [navigate])

  const langParam = searchParams.get('lang')
  useEffect(() => {
    if (langParam != null && langParam !== i18n.language) {
      changeLanguage(langParam)
    }
  }, [langParam])

  return (
    <Theme>
      {/* 画画遷移 */}
      <Routes>
        <Route path="app" element={<NavLayout title={t('app.title')}><Outlet /></NavLayout>}>
          <Route index element={<Navigate to="recents" replace />} />
          <Route path="recents" element={<Recents />} />
          <Route path="receipt" element={<Receipt />} />
          <Route path="cards">
            <Route index element={<Cards />} />
            <Route path="regist-result" element={<RegistCardResult />} />
          </Route>
          <Route path="accounts">
            <Route index element={<MyAccount />} />
            <Route path="edit" element={<EditAccount />} />
            <Route path="change-email" element={<ChangeEmail />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/" element={<Layout title={t('app.title')}><Outlet /></Layout>}>
          <Route index element={<Navigate to="app/recents" replace />} />
          <Route path="login" element={<SkipSigning><SignIn /></SkipSigning>} />
          <Route path="signup">
            <Route index element={<Navigate to="new" replace />} />
            <Route path="new" element={<SkipSigning><SignUpNew /></SkipSigning>} />
            <Route path="sending" element={<SignUpSending />} />
            <Route path="verify" element={<SignUpVerify />} />
            <Route path="complete" element={<RequireAuth><SignUpComplete /></RequireAuth>} />
          </Route>
          <Route path="reset-password" element={<SkipSigning><ResetPassword /></SkipSigning>} />
          <Route path="auth">
            <Route path="action" element={<AuthActionCallback />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      {/* ローディング表示 */}
      <LoadingProgress />
      {/* エラー表示 */}
      <ErrorSnackbar />
    </Theme>
  )
}
