import {
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp
} from 'firebase/app'
import {
  ReCaptchaEnterpriseProvider,
  ReCaptchaV3Provider,
  initializeAppCheck
} from 'firebase/app-check'
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  getAuth
} from 'firebase/auth'

// see .env.{NODE_ENV} file
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
}

const app = getApps().length <= 0
  ? initializeApp(firebaseConfig)
  : getApp()

// https://firebase.google.com/docs/app-check/web/custom-resource
if (process.env.NODE_ENV !== 'production') {
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.RECAPTCHA_DEBUG_TOKEN
}
export const appCheck = initializeAppCheck(app, {
  provider: (parseBool(process.env.RECAPTCHA_USE_ENTERPRISE)
    ? new ReCaptchaEnterpriseProvider(process.env.RECAPTCHA_SITE_KEY!)
    : new ReCaptchaV3Provider(process.env.RECAPTCHA_SITE_KEY!)),
  isTokenAutoRefreshEnabled: true
})

export default app
export const auth = getAuth(app)

export const googleProvider = new GoogleAuthProvider()
export const facebookProvider = new FacebookAuthProvider()
export const twitterProvider = new TwitterAuthProvider()

function parseBool (str?: string): boolean {
  switch (str) {
    case '1':
    case 't':
    case 'T':
    case 'true':
    case 'TRUE':
    case 'True':
      return true
  }
  return false
}
